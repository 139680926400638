/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"
import { useHistory, useRouteMatch } from "react-router-dom"

import { useStore } from "@store/index"
import { formatCustomerID } from "@utils/stringUtils"
import { mbNoData } from "@services/utils"
import Typography from "@components/ui/Typography/Typography"
import { Option } from "@framework/types/types"
import config from "@root/config"
import Header from "./components/ChannelTable/Header"
import ListItem from "./components/ChannelTable/ListItem"

import styles from "./ChannelList.module.scss"

export type TableMapper = Option & {
  isSortable?: boolean
}

const tableMapper: TableMapper[] = [
  { label: "Channel", value: "name", isSortable: false },
  { label: "Campaign Status", value: "status", isSortable: false },
  { label: "Linked Accounts", value: "linked", isSortable: false },
  {
    label: "",
    value: "action", // ??
    isSortable: false,
  },
]

type ChannelListProps = {}

const ChannelList: React.FC<ChannelListProps> = observer(() => {
  const history = useHistory()
  const match = useRouteMatch<any>()

  const accountId = match.params.id

  const {
    accountLinkingStore,
    accountStore: { account },
    productFeedStore: { GMC },
  } = useStore()

  const handleClick = (name: string) => () => {
    if (accountId) history.push(`/${name}/${accountId}/`)
  }

  const channels = useMemo(() => {
    const res = []

    res.push({
      name: "google-ads",
      title: "Google Ads",
      status: mbNoData(account?.campaignStatus),
      linked: [
        {
          name: "Google AdWord",
          value: mbNoData(account?.adAccountId, formatCustomerID),
        },
      ],
      iconSrc: "/images/googleAdwords.svg",
      link: "/google-ads",
    })

    if (accountLinkingStore.linkedFacebookAccount)
      res.push({
        name: "meta-ads",
        title: "Facebook Ads",
        status: "-",
        linked: [
          {
            name: "Facebook Ads id",
            value: accountLinkingStore.linkedFacebookAccount.adAccountId,
          },
        ],
        iconSrc: "/images/facebook_icon.svg",
        link: "/",
      })

    if (!config.HIDE_SHOPIFY && GMC.isConnected)
      res.push({
        name: "shopping",
        title: "Product Feed",
        status: "-",
        linked: [
          // { name: "GMC", value: "bali.resorts" },
          // { name: "Spotify", value: "bali.resorts" },
        ],
        iconSrc: "/images/shopping-bag-64.png",
        link: "/shopping",
      })
    return res
  }, [
    GMC.isConnected,
    account?.adAccountId,
    account?.campaignStatus,
    accountLinkingStore.linkedFacebookAccount,
  ])

  return (
    <div className={styles.root}>
      <Typography type="h2">Activated channels</Typography>
      <div className={styles.body}>
        <Header mapper={tableMapper} />
        <div className={styles.tableBody}>
          {channels.map((item) => (
            <ListItem
              data={item}
              onClick={handleClick(item.name)}
              // disabled={item.disabled}
            />
          ))}
        </div>
      </div>
    </div>
  )
})

export default ChannelList
