import { AxiosResponse } from "axios"
import {
  AdsReportType,
  CampaignEntity,
  CampaignReportDataType,
  KeywordReportType,
  KeywordEntityType,
  PMaxCampaignEntity,
  PMaxProductReportType,
  QueryReportType,
} from "@framework/types/account"
import {
  transformCreateFormForAPI,
  transformUpdateFormForAPI,
} from "@store/manage-campaigns/validation/budget"
import { AUTH_TOKEN_KEY } from "@framework/constants/auth"
import {
  AssetGroupEntity,
  AudiencesEntity,
  CallToActionTypes,
  CampaignBidStrategyType,
  ConditionResponseType,
  GoogleAdsLocation,
  ListingGroupEntity,
  ListingGroupOptionsEntity,
  PreviewLinkEntity,
  Rule,
} from "@framework/types/manageCampaign"
import HttpService from "./http.service"
import config from "../config"
import { ID } from "../framework/types/types"

export type UpdateCampaignBidStrategyRequestType = {
  campaignId: number
  biddingStrategyType: CampaignBidStrategyType
  impressionShare?: number
  targetValue?: number
  adsLocation?: string
}

export interface CampaignReportResponse {
  data: CampaignReportDataType[]
}

export interface TopKeywordsResponse {
  data: KeywordReportType[] | null
}

export interface NegativeKeywordsResponse {
  data: KeywordEntityType[] | null
}

export interface AdCopyListResponse {
  data: AdsReportType[] | null
}

export interface PMaxProductsResponse {
  data: PMaxProductReportType[] | null
}

export interface SearchQueryResponse {
  data: QueryReportType[] | null
}

export interface GetPmaxCampaignResponse {
  data?: PMaxCampaignEntity | null
}

export interface GetCampaignListResponse {
  data: CampaignEntity[]
}

export interface GetAssetGroupsResponse {
  data: AssetGroupEntity[]
}

export interface GetAssetGroupResponse {
  data: AssetGroupEntity
}

export interface GetAssetListingGroupsResponse {
  data: ListingGroupEntity
}

export interface GetAssetListingGroupOptionsResponse {
  data: ListingGroupOptionsEntity
}

export interface AddAssetListingGroupFiltersDataType {
  case: string
  values: string[]
}

export interface RemoveAssetListingGroupFiltersDataType {
  filterIds: number[]
}

export interface GetAudiencesDataType {
  data: AudiencesEntity[]
}

export interface PreviewLinkDataType {
  data: PreviewLinkEntity
}

export interface AddAudienceRequestType {
  audienceId: number
}

export interface GetRulesResponse {
  data: Rule[] | null
}

export interface CreateRulesResponse {
  data: Rule
}

export type AddRuleRequestDataType = {
  active: boolean
  description: string
  name: string
}

export type UpdateRuleRequestDataType = {
  active?: boolean
  description?: string
  name?: string
}

export type CreateCampaignResponse = {
  data: {
    AdGroups: number
    Ads: number
    Campaigns: number
    Keywords: number
    NegativeKeywords: number
  }
}

export type GetLocationsResponse = {
  data: GoogleAdsLocation[]
}

export type GetAssetGroupsListStatusResponse = {
  data: {}
}

export type UpdateAssetGroupImageNameRequestType = {
  name: string
}

export type UpdateAssetGroupRequestType = {
  textAssets: {
    assetType: string
    content: string
  }[]
  siteLinks: {
    description1: string
    description2: string
    linkText: string
    finalUrl: string
  }[]
  callToActionType: CallToActionTypes
}

export type CreateAssetGroupRequestType = {
  textAssets: {
    assetType: string
    content: string
  }[]
  name: string
  finalUrl: string
}

export type UpdateAssetGroupFinalUrlRequestType = {
  finalUrl: string
  name: string
}

export type UploadAssetGroupImagesRequestType = {
  aspectRatio: string
  data: string
  name: string
  type: "image" | "logo"
}

export type UploadAssetGroupYouTubeLinkRequestType = {
  url: string
  id?: number
  name?: string
}

class AccountCampaignAPI extends HttpService {
  getCampaignPackageLink = (accountId: number): string => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    return this.getBaseUrl(
      `download/gads-export/${accountId}/package?token=${token}`
    )
  }

  getCampaignReports = (
    accountId: ID,
    params: {
      from: string
      to: string
      includePmaxDrafts?: boolean
      includeAdGroups?: boolean
      compare?: boolean
    }
  ): Promise<AxiosResponse<CampaignReportResponse>> =>
    this.get(`property/${accountId}/campaign-report`, {
      includePmaxDrafts: false,
      includeAdGroups: false,
      compare: false,
      ...params,
    })

  getTopKeywordsReports = (
    accountId: ID,
    params: {
      from: string
      to: string
      campaignIds?: number[]
    }
  ): Promise<AxiosResponse<TopKeywordsResponse>> =>
    this.get(`property/${accountId}/top-keywords`, params)

  getNegativeKeywordsReports = (
    accountId: ID,
    params: {
      from: string
      to: string
      campaignIds?: number[]
    }
  ): Promise<AxiosResponse<NegativeKeywordsResponse>> =>
    this.get(`property/${accountId}/negative-keywords`, params)

  getAdCopyReports = (
    accountId: ID,
    params: {
      from: string
      to: string
      campaignIds?: number[]
    }
  ): Promise<AxiosResponse<AdCopyListResponse>> =>
    this.get(`property/${accountId}/ad-copies-report`, params)

  getPMaxProductReports = (
    accountId: ID,
    params: {
      from: string
      to: string
      campaignIds?: number[]
    }
  ): Promise<AxiosResponse<PMaxProductsResponse>> =>
    this.get(`property/${accountId}/pmax-products`, params)

  getSearchQueriesReports = (
    accountId: ID,
    params: {
      from: string
      to: string
      campaignIds?: number[]
    }
  ): Promise<AxiosResponse<SearchQueryResponse>> =>
    this.get(`property/${accountId}/search-terms`, params)

  getPMaxCampaignById = (
    accountId: ID,
    campaignId: ID
  ): Promise<AxiosResponse<GetPmaxCampaignResponse>> =>
    this.get(`property/${accountId}/pmax-campaigns/${campaignId}`)

  createPMaxCampaign = (
    accountId: ID,
    campaignData: Partial<any>
  ): Promise<AxiosResponse<any>> => {
    const payload = transformCreateFormForAPI(campaignData)
    return this.post(`property/${accountId}/mutate/pmax-campaign`, payload)
  }

  updatePMaxCampaignById = (
    accountId: ID,
    campaignId: ID,
    campaignData: Partial<any>
  ): Promise<AxiosResponse<any>> => {
    const payload = transformUpdateFormForAPI(campaignData)
    return this.put(
      `property/${accountId}/mutate/pmax-campaign/${campaignId}`,
      payload
    )
  }

  getCampaignsList = (
    accountId: ID
  ): Promise<AxiosResponse<GetCampaignListResponse>> =>
    this.get(`property/${accountId}/campaigns`)

  getAssetGroups = (
    accountId: ID,
    campaignId: ID
  ): Promise<AxiosResponse<GetAssetGroupsResponse>> =>
    this.get(`property/${accountId}/pmax-campaigns/${campaignId}/asset-groups`)

  getAssetGroup = (
    accountId: ID,
    assetGroupId: ID
  ): Promise<AxiosResponse<GetAssetGroupResponse>> =>
    this.get(`property/${accountId}/asset-groups/${assetGroupId}`)

  updateAssetGroup = (
    accountId: ID,
    assetGroupId: ID,
    data: UpdateAssetGroupRequestType
  ): Promise<AxiosResponse<any>> =>
    this.put(
      `property/${accountId}/asset-groups/${assetGroupId}/mutate/assets`,
      data
    )

  createAssetGroup = (
    accountId: ID,
    campaignId: ID,
    data: CreateAssetGroupRequestType
  ): Promise<AxiosResponse<any>> =>
    this.post(
      `property/${accountId}/pmax-campaigns/${campaignId}/asset-groups`,
      data
    )

  updateAssetGroupFinalUrl = (
    accountId: ID,
    assetGroupId: ID,
    data: UpdateAssetGroupFinalUrlRequestType
  ): Promise<AxiosResponse<any>> =>
    this.put(`property/${accountId}/asset-groups/${assetGroupId}/mutate`, data)

  deleteAssetGroupImage = (
    accountId: ID,
    assetGroupId: ID,
    imageId: ID
  ): Promise<AxiosResponse<any>> =>
    this.delete(
      `property/${accountId}/asset-groups/${assetGroupId}/images/${imageId}`
    )

  updateAssetGroupImageName = (
    accountId: ID,
    assetGroupId: ID,
    imageId: ID,
    data: UpdateAssetGroupImageNameRequestType
  ): Promise<AxiosResponse<any>> =>
    this.patch(
      `property/${accountId}/asset-groups/${assetGroupId}/images/${imageId}/rename`,
      data
    )

  uploadAssetGroupImages = (
    accountId: ID,
    assetGroupId: ID,
    data: UploadAssetGroupImagesRequestType
  ): Promise<AxiosResponse<any>> =>
    this.post(`property/${accountId}/asset-groups/${assetGroupId}/images`, data)

  uploadAssetGroupYouTubeLink = (
    accountId: ID,
    assetGroupId: ID,
    data: UploadAssetGroupYouTubeLinkRequestType
  ): Promise<AxiosResponse<any>> =>
    this.post(
      `property/${accountId}/asset-groups/${assetGroupId}/youtube-videos`,
      data
    )

  deleteAssetGroupYouTubeLink = (
    accountId: ID,
    assetGroupId: ID,
    videoId: ID
  ): Promise<AxiosResponse<any>> =>
    this.delete(
      `property/${accountId}/asset-groups/${assetGroupId}/youtube-videos/${videoId}`
    )

  getAssetListingGroups = (
    accountId: ID,
    assetGroupId: ID
  ): Promise<AxiosResponse<GetAssetListingGroupsResponse>> =>
    this.get(
      `property/${accountId}/asset-groups/${assetGroupId}/listing-groups`
    )

  createAssetListingGroups = (
    accountId: ID,
    assetGroupId: ID
  ): Promise<AxiosResponse<any>> =>
    this.post(
      `property/${accountId}/asset-groups/${assetGroupId}/listing-groups`,
      {}
    )

  getAssetListingGroupOptions = (
    accountId: ID,
    assetGroupId: ID,
    filterId: ID
  ): Promise<AxiosResponse<GetAssetListingGroupOptionsResponse>> =>
    this.get(
      `property/${accountId}/asset-groups/${assetGroupId}/listing-groups/${filterId}/options`
    )

  addAssetListingGroupFilters = (
    accountId: ID,
    assetGroupId: ID,
    filterId: ID,
    data: AddAssetListingGroupFiltersDataType
  ): Promise<AxiosResponse<any>> =>
    this.put(
      `property/${accountId}/asset-groups/${assetGroupId}/listing-groups/${filterId}/add`,
      data
    )

  /* Should be used when selected subdivision type differs from existing one */
  setAssetListingGroupFilters = (
    accountId: ID,
    assetGroupId: ID,
    filterId: ID,
    data: AddAssetListingGroupFiltersDataType
  ): Promise<AxiosResponse<any>> =>
    this.put(
      `property/${accountId}/asset-groups/${assetGroupId}/listing-groups/${filterId}/set`,
      data
    )

  removeAssetListingGroupFilters = (
    accountId: ID,
    assetGroupId: ID,
    filterId: ID,
    data: RemoveAssetListingGroupFiltersDataType
  ): Promise<AxiosResponse<any>> =>
    this.put(
      `property/${accountId}/asset-groups/${assetGroupId}/listing-groups/${filterId}/remove`,
      data
    )

  /* Delete listing-groups tree */
  removeAssetListingGroup = (
    accountId: ID,
    assetGroupId: ID
  ): Promise<AxiosResponse<any>> =>
    this.delete(
      `property/${accountId}/asset-groups/${assetGroupId}/listing-groups`
    )

  getAudiences = (
    accountId: ID,
    assetGroupId: ID
  ): Promise<AxiosResponse<GetAudiencesDataType>> =>
    this.get(`property/${accountId}/asset-groups/${assetGroupId}/audiences`)

  addAudience = (
    accountId: ID,
    assetGroupId: ID,
    data: AddAudienceRequestType
  ) =>
    this.post(
      `property/${accountId}/asset-groups/${assetGroupId}/audiences/set`,
      data
    )

  generatePreview = (
    accountId: ID,
    assetGroupId: ID
  ): Promise<AxiosResponse<PreviewLinkDataType>> =>
    this.post(
      `property/${accountId}/asset-groups/${assetGroupId}/generate-preview`,
      {}
    )

  updateCampaign = (accountId: ID, data: any): Promise<AxiosResponse<any>> =>
    this.put(`property/${accountId}/mutate/campaign`, data)

  updateCampaignBidStrategy = (
    accountId: ID,
    data: UpdateCampaignBidStrategyRequestType
  ): Promise<AxiosResponse<any>> =>
    this.put(`property/${accountId}/mutate/campaign/bidding-strategy`, data)

  getRules = (accountId: number): Promise<AxiosResponse<GetRulesResponse>> =>
    this.get(`property/${accountId}/rules`)

  addRule = (
    accountId: number,
    data: AddRuleRequestDataType
  ): Promise<AxiosResponse<CreateRulesResponse>> =>
    this.post(`property/${accountId}/rule`, data)

  updateRule = (
    accountId: number,
    ruleId: number,
    data: UpdateRuleRequestDataType
  ): Promise<AxiosResponse<CreateRulesResponse>> =>
    this.put(`property/${accountId}/rule/${ruleId}`, data)

  deleteRule = (
    accountId: number,
    ruleId: number
  ): Promise<AxiosResponse<CreateRulesResponse>> =>
    this.delete(`property/${accountId}/rule/${ruleId}`)

  addRuleCondition = (
    accountId: number,
    ruleId: number,
    data: ConditionResponseType
  ): Promise<AxiosResponse<GetRulesResponse>> =>
    this.post(`property/${accountId}/rule/${ruleId}/condition`, data)

  deleteRuleCondition = (
    accountId: number,
    ruleId: number,
    conditionsId: string
  ): Promise<AxiosResponse<any>> =>
    this.delete(
      `property/${accountId}/rule/${ruleId}/condition/${conditionsId}`
    )

  updateAdGroup = (accountId: number, data: any): Promise<AxiosResponse<any>> =>
    this.put(`property/${accountId}/mutate/adgroup`, data)

  updateAd = (accountId: ID, data: any): Promise<AxiosResponse<any>> =>
    this.put(`property/${accountId}/mutate/ad`, data)

  updateKeyword = (accountId: ID, data: any): Promise<AxiosResponse<any>> =>
    this.put(`property/${accountId}/mutate/keyword`, data)

  addKeywords = (
    accountId: ID,
    data: { keywords: string; adgroupId: number; url?: string }
  ): Promise<AxiosResponse<any>> =>
    this.post(`property/${accountId}/mutate/keywords`, data)

  addNegativeKeywords = (
    accountId: ID,
    data: {
      keywords: string
      campaignId: number
      adgroupId?: number
    }
  ): Promise<AxiosResponse<any>> =>
    this.post(`property/${accountId}/mutate/negative-keywords`, data)

  createCampaign = (
    accountId: ID
  ): Promise<AxiosResponse<CreateCampaignResponse>> =>
    this.put(`property/${accountId}/create-campaigns`, {})

  locationSuggestions = (
    params:
      | {
          location: string
          country?: string
        }
      | {
          locationIds: number[]
        }
  ): Promise<AxiosResponse<GetLocationsResponse>> =>
    this.get(`google-ads/location/suggestions`, params)
}

export default new AccountCampaignAPI({
  API_URL: config.API_ROOT,
})
