import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import Box from "@components/ui/Box/Box"
import Icon from "@components/ui/Icon/Icon"
import Image from "@components/ui/Image/Image"
import { numberWithCommas } from "@utils/numberUtils"
import { MetaCampaignReportData } from "@pages/MetaAdsChannel/types"

import styles from "./MediaSection.module.sass"

interface MediaSectionProps {
  data: MetaCampaignReportData
}

export const MediaSection: React.FC<MediaSectionProps> = observer(
  ({ data }) => (
    <Box className={clsx(styles.root)} elevation={1}>
      <div className={styles.mediaContainer}>
        <Image src={data.previewUrl} className={styles.image} alt="Preview" />

        <Typography
          className={styles.views}
          type="body1"
          color="black03Color"
          title="People are watching"
        >
          <Icon name="eye" />
          {numberWithCommas(data.totalViews ?? 0)}
        </Typography>
      </div>
    </Box>
  )
)

export default MediaSection
