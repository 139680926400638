import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"

import Stack from "@components/ui/Stack/Stack"
import { metricsDescriptors } from "@framework/constants/metrics"
import { initArray, randomNumber } from "@utils/numberUtils"
import useToggle from "@framework/hooks/useToggle"
import styles from "./Winners.module.scss"
import ReportDetailsModal from "../components/ReportDetails/ReportDetails"
import ReportCard from "../components/ReportCard/ReportCard"
import { MetaCampaignReportData, ViewType } from "../types"
import { generateCampaignReport } from "../mock"
import Table from "../components/Table/Table"
import ReportControlPanel from "../ReportControlPanel"
import mapper from "../General/mapper"
import PerformanceGoalCard, {
  PerformanceGoalCardProps,
} from "../components/PerformanceGoalCard/PerformanceGoalCard"

type WinnersPageProps = {
  className?: string
}

const WinnersPage: React.FC<WinnersPageProps> = observer(({ className }) => {
  const { accountStore } = useStore()

  const [view, setView] = React.useState<ViewType>("card")

  const [activeGoal, setActiveGoal] = React.useState<number>(0)

  const modal = useToggle()

  // TODO provide metrics list from filter
  const activeMetricsMock = metricsDescriptors

  // TODO API integration
  const reportsMock = React.useMemo(
    () =>
      initArray<MetaCampaignReportData>(
        performanceGoalOptions[activeGoal].total ?? 0,
        (idx) => generateCampaignReport(idx)
      ),
    [activeGoal]
  )

  return (
    <div className={clsx(styles.root, className)}>
      <Stack direction="column" gutter="20" align="stretch">
        <ReportControlPanel view={view} onViewChange={setView} />

        <div className={styles.goalsGrid}>
          {performanceGoalOptions.map((item, idx) => (
            <PerformanceGoalCard
              {...item}
              active={activeGoal === idx}
              onClick={() => setActiveGoal(idx)}
              key={item.title}
            />
          ))}
        </div>

        {view === "table" && (
          <Table
            className={styles.table}
            mapper={mapper}
            data={reportsMock}
            summary={summaryReport}
          />
        )}

        {view === "card" && (
          <div className={styles.grid}>
            {reportsMock.map((report) => (
              <ReportCard
                data={report}
                metrics={activeMetricsMock}
                onClick={modal.handleToggle}
                key={report.id}
              />
            ))}
          </div>
        )}
      </Stack>

      <ReportDetailsModal
        open={modal.isOpened}
        onClose={() => modal.setOpened(false)}
      />
    </div>
  )
})

export default WinnersPage

const performanceGoalOptions: PerformanceGoalCardProps[] = [
  {
    title: "Winner",
    description: "Creatives, that reached your goal within the time period",
    status: "winner",
    total: randomNumber(0, 100),
  },
  {
    title: "Losers",
    description:
      "Creatives, that have not reached your goal within the time period",
    status: "loser",
    total: randomNumber(0, 100),
  },
  {
    title: "Scaling",
    description: "Creatives, that spend more of your goal",
    total: randomNumber(0, 100),
  },
  {
    title: "New",
    description: "Creatives, that launched within the specific period",
    total: randomNumber(0, 100),
  },
]

const summaryReport = generateCampaignReport(999, false)
