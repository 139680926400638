import React, { FC, MutableRefObject, useEffect, useRef, useState } from "react"
import { Button } from "@components/ui/Button"
// eslint-disable-next-line import/no-extraneous-dependencies
import Cropper, { Point } from "react-easy-crop"
import getCroppedImg from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupImagesSidebar/cropImage"
import Stack from "@components/ui/Stack/Stack"
import { PreviewFile } from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupImagesSidebar/AssetImagesUpload"
import { observer } from "mobx-react-lite"
import Icon from "@components/ui/Icon/Icon"
import { useAlert } from "react-alert"
import styles from "../AssetGroupImagesSidebar/AssetGroupImagesSidebar.module.scss"

interface AssetImagesCropProps {
  localFile?: PreviewFile | null
  file?: { url: string; name: string; id: number; type: string }
  onClose: () => void
  setCroppedImagesList: (
    list: {
      name: string
      aspect: string
      file: any
      id?: number
    }[]
  ) => void
}
export interface AspectRatio {
  wide: { type: "wide_logo"; value: number }
  square: { type: "square"; value: number }
}
const ASPECT_RATIO: AspectRatio = {
  wide: { type: "wide_logo", value: 4 / 1 },
  square: { type: "square", value: 1 / 1 },
}
const AssetImagesCrop: FC<AssetImagesCropProps> = observer(
  ({ localFile, file, setCroppedImagesList, onClose }) => {
    const alert = useAlert()
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [aspect, setAspect] = useState<AspectRatio[keyof AspectRatio]>(
      ASPECT_RATIO.wide
    )
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<{
      width: number
      height: number
      x: number
      y: number
    }>({ width: 0, height: 0, x: 0, y: 0 })
    const localImage: MutableRefObject<string> = useRef<string>(
      localFile ? URL.createObjectURL(localFile.image) : ""
    )
    const image: MutableRefObject<string> = useRef<string>(file ? file.url : "")

    const [allowCropping, setAllowCropping] = useState(false)

    const [croppedImages, setCroppedImages] = useState<
      {
        ratioType: AspectRatio[keyof AspectRatio]
        croppedAreaPixels: {
          width: number
          height: number
          x: number
          y: number
        }
        crop: { x: number; y: number }
        selected: boolean
      }[]
    >([
      {
        ratioType: ASPECT_RATIO.wide,
        croppedAreaPixels: { width: 0, height: 0, x: 0, y: 0 },
        crop: { x: 0, y: 0 },
        selected: true,
      },
      {
        ratioType: ASPECT_RATIO.square,
        croppedAreaPixels: { width: 0, height: 0, x: 0, y: 0 },
        crop: { x: 0, y: 0 },
        selected: false,
      },
    ])

    // @ts-ignore
    const onCropComplete = (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)
    }

    const onCrop = async () => {
      if (localFile) {
        const getOnlySelectedImages = croppedImages.filter(
          (img) => img.selected
        )
        if (getOnlySelectedImages.length === 0) {
          alert.error("At least one logo should be selected")
          return
        }
        const data = await Promise.all([
          ...getOnlySelectedImages.map((img) =>
            getCroppedImg(
              localImage.current,
              img.croppedAreaPixels,
              localFile?.image.type
            ).then((res) => ({
              file: res,
              name: localFile.name,
              aspect: img.ratioType.type,
            }))
          ),
        ])

        setCroppedImagesList(data)
      } else if (file) {
        const getOnlySelectedImages = croppedImages.filter(
          (img) => img.selected
        )
        if (getOnlySelectedImages.length === 0) {
          alert.error("At least one logo should be selected")
          return
        }
        const data = await Promise.all([
          ...getOnlySelectedImages.map((img) =>
            getCroppedImg(image.current, img.croppedAreaPixels, file.type).then(
              (res) => ({
                file: res,
                name: file.name,
                aspect: img.ratioType.type,
                id: file.id,
              })
            )
          ),
        ])
        setCroppedImagesList(data)
      }
    }

    useEffect(() => {
      setCroppedImages((prevState) => {
        const state = prevState
        const res = state.map((img) => {
          if (img.ratioType.type === aspect.type) {
            return {
              ...img,
              croppedAreaPixels,
            }
          }
          return img
        })
        return res
      })
    }, [croppedAreaPixels])

    const onInteractionEnd = () => {
      setCroppedImages((prevState) => {
        const state = prevState
        const res = state.map((img) => {
          if (img.ratioType.type === aspect.type) {
            return {
              ...img,
              crop,
            }
          }
          return img
        })
        return res
      })
      setAllowCropping(false)
    }

    const setAspectRatio = (ratio: AspectRatio[keyof AspectRatio]) => {
      setAspect(ratio)
      setCroppedImages((prevState) => {
        const state = prevState
        const res = state.map((img) => {
          if (img.ratioType.type === ratio.type) {
            return {
              ...img,
              selected: !img.selected,
            }
          }
          return img
        })
        return res
      })
    }

    useEffect(() => {
      setAspect(ASPECT_RATIO.wide)
    }, [])

    useEffect(
      () => () => {
        if (localImage.current) {
          URL.revokeObjectURL(localImage.current)
          localImage.current = ""
        } else if (image.current) {
          URL.revokeObjectURL(image.current)
          image.current = ""
        }
      },
      []
    )

    const onCropChange = (location: Point) => {
      if (allowCropping) setCrop(location)
    }

    const getCropValues = () => {
      const area = croppedImages.find(
        (img) => img.ratioType.type === aspect.type
      ) as { crop: { x: number; y: number } }
      if (!allowCropping) {
        return area.crop
      }
      return crop
    }

    const getIcon = (ratio: AspectRatio[keyof AspectRatio]) => {
      const img = croppedImages.find((i) => i.ratioType.type === ratio.type)
      if (img?.selected) {
        return <Icon name="done" circle />
      }
      return <Icon name="cross" circle />
    }

    const getButtonColorStatus = (ratio: AspectRatio[keyof AspectRatio]) => {
      const img = croppedImages.find((i) => i.ratioType.type === ratio.type)
      if (img?.selected) {
        return "primary"
      }
      return "secondary"
    }

    return (
      <div>
        <Stack
          className={styles.cropper}
          direction="column"
          justify="center"
          align="center"
        >
          <div className={styles.cropperWrap}>
            <Cropper
              image={image.current ? image.current : localImage.current}
              crop={{ ...getCropValues() }}
              // zoom={zoom}
              aspect={aspect.value}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              onInteractionEnd={onInteractionEnd}
              onInteractionStart={() => setAllowCropping(true)}
            />
          </div>
          <Stack
            className={styles.cropperAspectActions}
            direction="row"
            justify="space-between"
          >
            <Button
              color={getButtonColorStatus(ASPECT_RATIO.wide)}
              onClick={() => setAspectRatio(ASPECT_RATIO.wide)}
              before={getIcon(ASPECT_RATIO.wide)}
            >
              Landscape: 4 / 1
            </Button>
            <Button
              color={getButtonColorStatus(ASPECT_RATIO.square)}
              onClick={() => setAspectRatio(ASPECT_RATIO.square)}
              before={getIcon(ASPECT_RATIO.square)}
            >
              Square: 1 / 1
            </Button>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justify="flex-start"
          className={styles.cropperActions}
        >
          <Button color="primary" onClick={onCrop}>
            Crop
          </Button>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Stack>
      </div>
    )
  }
)

export default AssetImagesCrop
